.app {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.workers-list, .group {
  font-size: 0.6em;
  width: 400px;
  min-height: 100px;
  border: 2px solid #ccc;
  padding: 10px;
  border-radius: 8px;
}

.worker {
  margin: 5px 0;
  padding: 5px;
  background-color: lightblue;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
}

.group {
  background-color: #f9f9f9;
}



/*

export const Testpage = ()=> {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      category: '',
      checkbox: [],
      radio: ''
    }
  });

  // Colección de inputs con índices de (0 a 3)
  // 0 es la posición de #input1, 3 es la posición de #input4
  let inputs = document.querySelectorAll('[id^="input"]');
  // Asignar evento para ejecutar función
  inputs.forEach(input => input.addEventListener('keypress', nextFocus));

  // Recibe evento como parámetro
  function nextFocus(e) {
      if(e.keyCode == 13){
          // e.target es el elemento que lanzó el evento
          // Obtener número desde ID, eliminando la palabra input (1 a 4)
          let actual = parseInt(e.target.id.replace('input', ''));
          // Solo si no es el último
          if(actual < inputs.length) {
              // Evitar comportamiento normal del evento
              e.preventDefault();
              // Activar siguiente campo
              inputs[actual].focus();
          }
      }
  }
  const prueba = (data) => {
    console.log(data)
  }

  const fechaComoCadena = "2022-11-09 23:37:22"; // día lunes
  const dias = [
    'domingo',
    'lunes',
    'martes',
    'miércoles',
    'jueves',
    'viernes',
    'sábado',
  ];
  const numeroDia = new Date(fechaComoCadena).getDay();
  const nombreDia = dias[numeroDia];
  console.log("Nombre de día de la semana: ", nombreDia);
   
  return (
    <form onSubmit={handleSubmit(prueba)}>
      <input {...register("firstName")} placeholder="First name" />
      <input {...register("lastName", )} placeholder="Last name" />

      <select {...register("category")} >
        
        <option value="">Select...</option>
        <option value="A">Category A</option>
        <option value="B">Category B</option>
      </select>
      <br/>
      <br/>
      <label>A</label>
      <input {...register("checkbox")} type="checkbox" value="A" />
      <label>B</label>
      <input {...register("checkbox")} type="checkbox" value="B" />
      <label>C</label>
      <input {...register("checkbox")} type="checkbox" value="C" />
      
      <label>A</label>
      <input {...register("radio")} type="radio" value="A" />
      <label>B</label>
      <input {...register("radio")} type="radio" value="B" />
      <label>C</label>
      <input {...register("radio")} type="radio" value="C" />
      <br/><br/>
      <input type="text" id="input1" autoFocus/><br/>
      <input type="text" id="input2"/><br/>
      <input type="text" id="input3"/><br/>
      <input type="text" id="input4"/><br/>
      <input type="submit"/>
    </form>
  );
}
*/